import Styled from 'styled-components';
import Button from '../../atoms/Button/ffg/Button.style';

import { VIEWPORT_XXS_MAX } from '../../util/mediaQueries';

/* private variables */
const formMinWidth = '280px'; // must not be <280px due to UI problems with fields
const formPadding = '1rem';
const fieldMargin = '1rem';

// prevent unexpected element reordering when activating input
const controlMinWidth = `calc(${formMinWidth} - ${formPadding} * 2)`;

export const Form = Styled.form`
  width: 100%;
`;

export const HorizontalWrapper = Styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border: none;
  width: 100%;
  > * {
    flex: 1 0 auto; /* will work with IE11 (more or less) */
    flex-basis: calc(25% - ${fieldMargin}); /* will not apply to IE11 */
    margin: calc(${fieldMargin} * 0.5);
    min-width: ${controlMinWidth};
    width: auto;
  }
  ${Button} {
    margin: 0;
    padding: 1rem;
    width: 100%;
  }
`;

export const VerticalWrapper = Styled.div`
  display: flex;
  flex-direction:column;
  margin: 0;
  border: none;
  width: 100%;

 > * {
    flex: 1 0 auto; /* will work with IE11 (more or less) */
    flex-basis: calc(25% - ${fieldMargin}); /* will not apply to IE11 */
    margin: calc(${fieldMargin} * 0.5);
    min-width: ${controlMinWidth};
    width: auto;
  }
  ${Button} {
    margin: 0;
    padding: 1em;
    float:right;
    width: 50%;

    @media ${VIEWPORT_XXS_MAX} {
      width: auto;
    }
  }
`;
