import React from 'react';
import PropTypes from 'prop-types';

import AmountField from '../../molecules/AmountField/AmountField';
import SelectField from '../../molecules/SelectField';
import Button from '../../atoms/Button';
import deepDefaultProps from '../../util/deepDefaultProps';

import {
  termOptions,
  purposeOptions,
  mapPurposeToCafePurpose,
  buildTargetUrl,
} from '../../util/formHelper';

import { Form, HorizontalWrapper, VerticalWrapper } from './LoanForm.style';

/**
 * Prefix for all keys within the WebStorage
 */
export const STORAGE_KEY_PREFIX = 'ffg-lead-';
export const STORAGE_KEY_RESUME_HASH = STORAGE_KEY_PREFIX + 'resumeHash';

class LoanForm extends React.PureComponent {

  static propTypes = {
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    affiliateData: PropTypes.object.isRequired,
  };

  static defaultProps = {
    minAmount: 1000,
    maxAmount: 100000,
  };

  /**
   * @constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const {
      affiliateData: {
        amount,
        term,
        purpose,
      },
    } = this.props;

    this.state = {
      amount,
      term,
      purpose,
    };
  }

  /**
   * Handles change event on amount input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handleAmountChange = (event, value) => {
    const { maxAmount, minAmount } = this.props;

    if (value >= minAmount && value <= maxAmount) {
      this.setState({
        amount: value,
      });
    }
  };

  /**
   * Handles change event on term input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handleTermChange = (event, value) => {
    const options = termOptions.filter(item => item.value === value);

    if (options.length) {
      this.setState({
        term: value,
      });
    }
  };

  /**
   * Handles change event on purpose input
   * Verifies new value and saves it into state
   *
   * @param {Number} value
   */
  handlePurposeChange = (event, value) => {
    const options = purposeOptions.filter(item => item.value === value);

    if (options.length) {
      this.setState({
        purpose: value,
      });
    }
  };

  /**
   * @param {Event} event
   */
  handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    if (!form.checkValidity()) return;

    const {
      affiliateData: {
        targetUrl, parameters, submitMode, subId,
      },
    } = this.props;
    const { amount, term, purpose } = this.state;


    const url = buildTargetUrl(targetUrl, {
      ...parameters,
      amount,
      purpose: mapPurposeToCafePurpose(purpose),
      subId,
      term,
    });
    switch (submitMode) {
      default:
      case 'REDIRECT':
        window.location.href = url;
        break;
      case 'WINDOW':
        window.open(url, '_blank');
        break;
      case 'AMP':
        window.open(url, '_top');
        break;
    }
  };

  /**
   * @returns {JSX}
   */
  render() {
    const {
      minAmount,
      maxAmount,
      affiliateData: {
        layout,
        buttonText,
      },
    } = this.props;

    const {
      amount,
      term,
      purpose,
    } = this.state;

    const Wrapper = layout.toUpperCase() === 'VERTICAL' ? VerticalWrapper : HorizontalWrapper;

    return (
      <Form method="post" onSubmit={this.handleSubmit}>
        <Wrapper>
          <AmountField
            name="amount"
            label="Nettodarlehensbetrag"
            onChange={this.handleAmountChange}
            placeholder="10.000"
            value={amount}
            min={minAmount}
            max={maxAmount}
            unit="Euro"
            required
            autoComplete="off"
          />
          <SelectField
            name="term"
            label="Laufzeit"
            onChange={this.handleTermChange}
            value={term.toString()}
            options={termOptions}
          />
          <SelectField
            name="purpose"
            label="Verwendungszweck"
            onChange={this.handlePurposeChange}
            value={purpose}
            options={purposeOptions}
            required
          />
          <div className="actionbar">{/* IE11 fix */}
            <Button type="submit" label={buttonText} />
          </div>
        </Wrapper>
      </Form>
    );
  }

}

export default deepDefaultProps({
  affiliateData: {
    buttonText: 'Kredite vergleichen',
    targetUrl: 'https://www.finanzcheck.de/kreditantrag/',
    amount: 10000,
    term: 84,
    purpose: 'OTHER',
    layout: '',
  },
})(LoanForm);
